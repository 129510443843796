<template>
   <div class="modal-row2 modal-height-max modal-content" style="overflow: hidden">
      <div style="position: sticky; top: 0" class="row gutters pt-2 pb-2 pl-3 pr-3">
         <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-9">
            <div class="form-group">
               <label for="name" class="required">{{ t('GROUP.NAME') }}: </label>
               <input type="text"
                      id="name"
                      placeholder="Nome"
                      :disabled="isView"
                      v-model="data.name"
                      name="name"
                      required="required"
                      class="form-control">
            </div>
         </div>
         <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-3 align-items-center d-flex">
            <div class="custom-control custom-switch">
               <input type="checkbox" class="custom-control-input" id="active" v-model="data.is_active" :disabled="isView">
               <label class="custom-control-label" for="active">{{ t('ALLOTMENT.ACTIVE') }}</label>
            </div>
         </div>
         <div class="col-12">
            <input type="text" name="company" v-model="search" id="company"
                   class="form-control" @keyup="filterCompany"
                   placeholder="Pesquisar Compania"/>
         </div>
      </div>
      <div class="modal-body modal-row2" style="overflow-x: hidden">
         <div class="row gutters">
            <div class="row w-100">
               <div v-for="(company, key) in companies" :key="key"
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 w-100 border-top2 ml-3">
                  <div class="p-2 row d-flex  w-100 mr-1">
                     <div class="w-100 font-weight-bolder col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                        {{ company.text }}
                     </div>
                     <div class="w-100 font-weight-bolder col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                        <div class="custom-control custom-switch list-primary text-left">
                           <input type="checkbox" class="custom-control-input"
                                  @click="setAddCompany(company)"
                                  :value="company.id"
                                  :disabled="isView"
                                  :id="`customSwitch${key}`" v-model="company.is_active">
                           <label class="custom-control-label " :for="`customSwitch${key}`">Selecionar</label>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="modal-footer" style="position: sticky">
         <button class="btn btn-secondary" data-dismiss="modal" @click="clearField">
            {{ t('ACTIONS.CLOSE') }}
         </button>
         <button class="btn btn-primary" @click="createGroup" v-if="!isView">
            {{ t('ACTIONS.SAVE') }}
         </button>
      </div>

   </div>
</template>
<script>

import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import GroupPublic from '../../services/GroupPublic';
import errorMsg from '../../components/errorMsg';

export default {
   name: "createPublicGroupComponent",
   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {t, toast}
   },
   props: {
      isEdit: {
         default: false,
         type: Boolean
      },
      isView: {
         default: false,
         type: Boolean
      },
      dataIndex: {
         default: () => {
            return {}
         },
         type: Object
      }
   },
   emits: ['create', 'close'],
   mixins: [errorMsg],
   watch: {
      dataIndex() {
         this.data = this.dataIndex;
      }
   },
   async mounted() {
      await this.getCompanies();
      if (this.dataIndex?.name?.length) {
         this.data = this.dataIndex;
         if (this.isEdit || this.isView) {
            this.mergeCompanies()
         }
      }
   },
   data() {
      return {
         data: {},
         companiesBackup: [],
         companies: [],
         search: null,
      }
   },
   methods: {
      mergeCompanies(){
         let data = this.companies.filter(item => {
            let hasItem = false;
            this.data.companies.forEach(company => {
               if (company.company_id == item.id) {
                  hasItem = true;
               }
            });
            return !hasItem;
         });
         this.companies = data;
         this.data.companies.forEach(item => {
            this.companies.push({
               id: item.company_id,
               text: item.company_name,
               is_active: item.is_active,
            });
         })
         this.data.companies = [];
         this.companies.sort(function(a,b) {
            return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
         });
         this.companiesBackup = this.companies;
      },
      clearField() {
         document.getElementById('closeX')?.click();
         this.$emit('close');
         this.data = {};
         this.search = null;
      },
      createGroup() {
         this.data.companies = this.companiesBackup.filter(item => {
            if (item.is_active !== undefined) {
               return item;
            }
         });
         if (this.validateFields(this.data)) {
            this.$store.commit('changeLoading', true);
            if (this.isEdit){
               GroupPublic.edit(this.data.id, this.data).then(() => {
                  document.getElementById('closeX').click();
                  this.data = {};
                  this.search = null;
                  this.$emit('create');
               }).catch(err => {
                  this.$store.commit('changeLoading', false);
                  this.errorMsg(err);
               })
               return;
            }
            GroupPublic.create(this.data).then(() => {
               document.getElementById('closeX').click();
               this.data = {};
               this.search = null;
               this.$emit('create');
            }).catch(err => {
               this.$store.commit('changeLoading', false);
               this.errorMsg(err);
            })
         }
      },
      validateFields(data) {
         let next = true
         if (!data.name?.length) {
            this.toast.error('Campo Nome é obrigatorio');
            next = false;
         }
         if (!data.companies.length) {
            this.toast.error('Selecione ao menos uma companhia');
            next = false;
         }
         data.is_active = !!data.is_active;
         return next;
      },
      async getCompanies() {
         this.$store.commit('changeLoading', true);
         await GroupPublic.getAllCompany().then(resp => {
            for (const key in resp.data) {
               this.companies.push({
                  id: key,
                  text: resp.data[key],
               })
            }
            this.companies.sort(function(a,b) {
               return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
            });
            this.companiesBackup = this.companies;
            this.$store.commit('changeLoading', false);
         }).catch(() => {
            this.$store.commit('changeLoading', false);
         })
      },
      setAddCompany(company) {
         this.companiesBackup.forEach(i => {
            if (i.id === company.id) {
               i.is_active = i.is_active === undefined ? true : !i.is_active;
            }
         })
      },
      filterCompany() {
         if (this.search?.search) {
            this.companies = this.companiesBackup.filter(i => {
               let nameCompany = i.text.toUpperCase();
               let seacher = this.search.toUpperCase();
               if (nameCompany.includes(seacher)) {
                  return i
               }
            })
         } else {
            this.companies = this.companiesBackup;
         }
      }
   }
}
</script>

<style scoped>

</style>