<template>
   <div>
      <page-header pageTitle="NAV.GROUPS" :btnNew="'ACTIONS.NEW'"
                   @newType="setAddModal"></page-header>
      <div v-if="this.items?.data && !this.items.data.validations" class="card mw-100">
         <div class="row mw-100">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
               <div class="font-weight-bolder mt-2 ml-4 text-left">
                  {{ this.t('GROUP.NAME') }}
               </div>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
               <div class="font-weight-bolder mt-2 text-left" style="margin-left: 1.5rem">
                  {{ this.t('GROUP.SITUATION') }}
               </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 font-bold"
                 style="font-size: 1rem;">
            </div>
         </div>
         <div v-for="(iten, key) in items.data" :key="iten.id" :class="key !=0 ? 'border-top2': ''">
            <div class="row mw-100 space-between mt-2">
               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div class="list-primary hide-text text-left">
                     <div class="w-100">
                        {{ iten.name }}
                     </div>
                  </div>
               </div>
               <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
                  <div class="list-primary hide-text text-left">
                     <div class="w-100">
                        {{ iten.is_active ? 'Ativo' : 'Inativo' }}
                     </div>
                  </div>
               </div>
               <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1" style="margin-left: -0.1rem">
                  <div class="text-right">
                     <a type="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" class="w-100"
                        style="font-size: 20px" id="listDropdown">
                        <i class="icon-more_vert" title="Opções"></i>
                     </a>
                     <div class="dropdown-menu dropdown-menu-right dropdown-black w-auto">
                        <div class="dropdown-item pointer text-white"
                             @click="setVieModal(iten)"
                             data-toggle="modal" data-target="#customModalTwo">
                           <i class="icon-eye1 mr-2 font-15"/>{{ t('ACTIONS.VIEW') }}
                        </div>
                        <div class="dropdown-item pointer text-white"
                             data-toggle="modal" data-target="#customModalTwo" @click="setEditModal(iten)">
                           <i class="icon-edit mr-2 font-15"/>{{ t('ACTIONS.EDIT') }}
                        </div>
                        <div class="dropdown-item pointer text-white" @click="copy(iten.id)"
                             data-cy="copyLink1">
                           <i class="icon-copy mr-2 font-15"/>{{ t('GROUP.COPY_PUBLIC_LINK') }}
                        </div>
                        <div class="dropdown-item pointer text-white" @click="copyMap(iten.id)">
                           <i class="icon-map mr-2 font-15"/>{{ t('GROUP.COPY_MAP_LINK') }}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="card" v-if="items && items.validations">
         <div class="card-header"></div>
         <div class="card-body pt-0">
            <div class="text-center">
               <h5>{{ t('GROUP.NONE_FOUND') }}</h5>
            </div>
         </div>
      </div>
      <modal :modal-large="true" :title-modal="modalTitle" @close="closeModal()">
         <template v-slot:title>{{ t(modalTitle) }}</template>
         <create-public-group-component v-if="modalSwitch === 1"
                                        @create="index()"
                                        @close="closeModal()"
                                        :data-index="item"
                                        :is-edit="modalEdit"
                                        :is-view="modalView"
         >
         </create-public-group-component>
      </modal>
      <pagination-component v-if="items?.data" :items="items" :to="'/grupos-publicos'"
                            @changePage="index(filter, $event)"></pagination-component>
   </div>
</template>

<script>
import pageHeader from '@/components/layouts/pageHeader';
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import GroupPublic from '../../services/GroupPublic';
import modal from '../../components/modal';
import createPublicGroupComponent from './createPublicGroupComponent';
import PaginationComponent from "../../components/layouts/PaginationComponent";

export default {
   name: "indexPublicGroup",
   components: {
      pageHeader,
      modal,
      createPublicGroupComponent,
      PaginationComponent
   },
   data() {
      return {
         items: [],
         modalSwitch: null,
         modalTitle: null,
         modalEdit: false,
         modalView: false,
         item: {}
      }
   },
   mounted() {
      this.index();
   },
   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {t, toast}
   },
   methods: {
      closeModal(){
         this.modalView = false;
         this.modalEdit = false;
         this.item = {};
         this.modalSwitch = null;
      },
      copy(id) {
         let link = window.location.host + '/corretores/' + id
         navigator.clipboard.writeText(link).then(() => {
            this.toast.success("Link de acesso à area publica copiado sucesso");
         });
      },
      copyMap(id){
         let link = window.location.host + '/mapas/' + id
         navigator.clipboard.writeText(link).then(() => {
            this.toast.success("Link de acesso aos mapas copiado sucesso");
         });
      },
      setVieModal(item){
         this.modalEdit = false;
         this.modalView = true;
         this.item = item;
         this.modalTitle = 'GROUP.VIEW_GROUP';
         this.modalSwitch = 1;
      },
      setEditModal(item){
         this.modalEdit = true;
         this.modalView = false;
         this.item = item;
         this.modalTitle = 'GROUP.EDIT_GROUP';
         this.modalSwitch = 1;
      },
      index(filter = null, page = 1) {
         this.modalSwitch = null;
         this.$store.commit('changeLoading', true);
         GroupPublic.index(filter, page).then(resp => {
            this.items = resp.data;
            this.$store.commit('changeLoading', false);
         }).catch(() => {
            this.$store.commit('changeLoading', false);
         })
      },
      setAddModal() {
         this.modalView = false;
         this.modalEdit = false;
         this.item = {};
         this.modalTitle = 'GROUP.ADD_NEW_GROUP';
         this.modalSwitch = 1;
      }
   }
}
</script>

<style scoped>

</style>